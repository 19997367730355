export enum EPermissionOption {
  ReadOnly = 1,
  Edit = 2,
  Admin = 3,
}

export const AccessLevels: { [accessLevelName: string]: EPermissionOption } = {
  ReadOnly: EPermissionOption.ReadOnly,
  Edit: EPermissionOption.Edit,
  Admin: EPermissionOption.Admin,
};

export enum EUserType {
  Internal = 'Internal',
  External = 'External',
}

export enum EEndpointFragment {
  Login = 'login',
  PasswordReset = 'password/reset',
  Refresh = 'refresh',
  RunResults = 'runResults',
  NomUpload = 'nomination-upload',
  InvoiceAttachments = 'invoices/attachments',
  PostInfoPostDetails = 'infopost-admin/post-infopostdetails',
  PostBisaInfoPostDetails = 'bisa/postinfopostdetails',
  GetPostingsDocumentById = 'infopost/postings',
  TariffUpload = "/infopost-admin/postings",
  GetNoticeDocumentById = 'infopost/notices',
}
