import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import {
  fetchAllNoticesSuccess,
  fetchGroupByPostingsList,
  fetchGroupByPostingsListSuccess,
  fetchHistoricalGasQuality,
  fetchHistoricalGasQualityError,
  fetchHistoricalGasQualitySuccess,
  fetchInfoPostTspInfo,
  fetchInfoPostTspInfoError,
  fetchInfoPostTspInfoSuccess,
  fetchInternalHistoricalGasQuality,
  fetchInternalHistoricalGasQualityError,
  fetchInternalHistoricalGasQualitySuccess,
  fetchMenuItemComments,
  fetchMenuItemCommentsSuccess,
  fetchNaesbList,
  fetchNaesbListError,
  fetchNaesbListSuccess,
  fetchNoticeCapacityReleaseIndicesInfo,
  fetchNoticeCapacityReleaseIndicesInfoError,
  fetchNoticeCapacityReleaseIndicesInfoSuccess,
  fetchNoticeInfo,
  fetchNoticeInfoError,
  fetchNoticeInfoSuccess,
  fetchNoticeTypeInfo,
  fetchNoticeTypeInfoError,
  fetchNoticeTypeInfoSuccess,
  fetchPostingsList,
  fetchPostingsListError,
  fetchPostingsListSuccess,
  fetchReceiptGasComposition,
  fetchReceiptGasCompositionError,
  fetchReceiptGasCompositionSuccess,
  fetchRollingAvgGasComposition,
  fetchRollingAvgGasCompositionError,
  fetchRollingAvgGasCompositionSuccess,
  fetchStrattonRidgeGasComposition,
  fetchStrattonRidgeGasCompositionError,
  fetchStrattonRidgeGasCompositionSuccess,
  removeSelectedInfoPostTSP,
  resetNoticeData,
  resetPostingsList,
  setSelectedInfoPostTSP,
  setSelectedTSPID,
} from './infopost.actions';
import {
  AllNoticesData,
  GroupByPostingData,
  IInfoPostState,
  initialInfoPostState,
} from './infopost.state';
import {
  createErrorState,
  createLoadingState,
  createSuccessState,
  ICachedDataState,
} from '../app/app.models';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ServiceProvider } from '@gms/tsp-api';
import { TSPConstant } from 'shared/utils/tsp.utils';

export const InfoPostFeatureKey = 'infoPost';

export const infoPostReducer = createReducer(
    initialInfoPostState,
    on(fetchNoticeInfo, (state) => ({
        ...state,
        notices: createLoadingState(state.notices),
    })),
    on(fetchNoticeInfoSuccess, (state, { notices }) => ({
        ...state,
        notices: createSuccessState(notices),
    })),
    on(fetchNoticeInfoError, (state, { error }) => ({
        ...state,
        notices: createErrorState(error, {}),
    })),
    on(fetchNoticeTypeInfo, (state) => ({
        ...state,
        noticeTypes: createLoadingState(state.noticeTypes),
    })),
    on(fetchNoticeTypeInfoSuccess, (state, { noticeTypes }) => ({
        ...state,
        noticeTypes: createSuccessState(noticeTypes),
    })),
    on(fetchNoticeTypeInfoError, (state, { error }) => ({
        ...state,
        noticeTypes: createErrorState(error, {}),
    })),
    on(fetchPostingsList, (state) => ({
        ...state,
        postings: createLoadingState(state.postings),
    })),
    on(fetchPostingsListSuccess, (state, { postings }) => ({
        ...state,
        postings: createSuccessState(postings),
    })),
    on(fetchPostingsListError, (state, { error }) => ({
        ...state,
        postings: createErrorState(error, {}),
    })),
    on(fetchNaesbList, (state) => ({
        ...state,
        naesbList: createLoadingState(state.naesbList),
    })),
    on(fetchNaesbListSuccess, (state, { naesb }) => ({
        ...state,
        naesbList: createSuccessState(naesb),
    })),
    on(fetchNaesbListError, (state, { error }) => ({
        ...state,
        naesbList: createErrorState(error, {}),
    })),
    on(resetPostingsList, (state) => ({
        ...state,
        postings: createSuccessState(null),
    })),
    on(resetNoticeData, (state) => ({
        ...state,
        notices: createSuccessState(null),
    })),
    on(fetchInfoPostTspInfo, (state) => ({
        ...state,
        tspServiceRequester: createLoadingState(state.tspServiceRequester),
    })),
    on(fetchInfoPostTspInfoSuccess, (state, { tspServiceRequester }) => ({
        ...state,
        tspServiceRequester: excludeSuppressTSP(tspServiceRequester)
    })),
    on(fetchInfoPostTspInfoError, (state, { error }) => ({
        ...state,
        tspServiceRequester: createErrorState(error, []),
    })),
    on(setSelectedInfoPostTSP, (state, { tspServiceRequester, overrideIfAlreadySet }) => ({
        ...state,
        headerInfoPostTSP: (overrideIfAlreadySet === undefined || overrideIfAlreadySet || !state.headerInfoPostTSP) &&
            tspServiceRequester
            ? tspServiceRequester
            : state.headerInfoPostTSP
    })),
    on(fetchMenuItemComments, (state) => ({
        ...state,
        menuitemsComments: createLoadingState(state.menuitemsComments)
    })),
    on(fetchMenuItemCommentsSuccess, (state, { menuitemsComments }) => ({
        ...state,
        menuitemsComments: createSuccessState(menuitemsComments),
    })),
    on(fetchNoticeInfoError, (state, { error }) => ({
        ...state,
        menuitemsComments: createErrorState(error, {}),
    })),
    on(setSelectedTSPID, (state, { selectedTspID }) => ({
        ...state,
        selectedTspID: selectedTspID,
    })),
    on(fetchNoticeCapacityReleaseIndicesInfo, (state) => ({
        ...state,
        noticeCapacityRelease: createLoadingState(state.noticeCapacityRelease),
    })),
    on(fetchNoticeCapacityReleaseIndicesInfoSuccess, (state, { capacityRelease }) => ({
        ...state,
        noticeCapacityRelease: createSuccessState(capacityRelease),
    })),
    on(fetchNoticeCapacityReleaseIndicesInfoError, (state, { error }) => ({
        ...state,
        noticeCapacityRelease: createErrorState(error, {}),
    })),
    on(fetchGroupByPostingsList, (state) => ({
        ...state,
        groupByPostingData: createLoadingState(state.groupByPostingData),
    })),

    on(fetchGroupByPostingsListSuccess, (state, { tspId, groupByPostingsData }) => {

        const postingDataById = state.groupByPostingData?.data || [];
        const existingIndex = postingDataById?.findIndex(item => item.tspId === tspId);

        const newData: GroupByPostingData = {
            tspId,
            data: groupByPostingsData
        };

        if (existingIndex !== -1) {
            postingDataById[existingIndex] = newData
        } else {
            postingDataById.push(newData)
        }

        return {
            ...state,
            groupByPostingData: createSuccessState(
                postingDataById
            ),
        };
    }),
    on(fetchAllNoticesSuccess, (state, { tspId, allNotices }) => {

        const noticesDataById = state.allNoticesData?.data || [];
        const existingIndex = noticesDataById?.findIndex(item => item.tspId === tspId);

        const newData: AllNoticesData = {
            tspId,
            data: allNotices
        };

        if (existingIndex !== -1) {
            noticesDataById[existingIndex] = newData
        } else {
            noticesDataById.push(newData)
        }

        return {
            ...state,
            allNoticesData: createSuccessState(
                noticesDataById
            ),
        };
    }),

    on(fetchReceiptGasComposition, (state) => ({
        ...state,
        receiptPointData: createLoadingState(state.receiptPointData)
    })),
    on(fetchReceiptGasCompositionSuccess, (state, { data }) => ({
        ...state,
        receiptPointData: createSuccessState(data)
    })),
    on(fetchReceiptGasCompositionError, (state, { error }) => ({
        ...state,
        receiptPointData: createErrorState(error, {}),
    })),
    on(fetchRollingAvgGasComposition, (state) => ({
        ...state,
        rollingAvgData: createLoadingState(state.rollingAvgData)
    })),
    on(fetchRollingAvgGasCompositionSuccess, (state, { data }) => ({
        ...state,
        rollingAvgData: createSuccessState(data)
    })),
    on(fetchRollingAvgGasCompositionError, (state, { error }) => ({
        ...state,
        rollingAvgData: createErrorState(error, {}),
    })),
    on(fetchStrattonRidgeGasComposition, (state) => ({
        ...state,
        strattonData: createLoadingState(state.strattonData)
    })),
    on(fetchStrattonRidgeGasCompositionSuccess, (state, {data}) => ({
        ...state,
        strattonData: createSuccessState(data)
    })),
    on(fetchStrattonRidgeGasCompositionError, (state, { error }) => ({
        ...state,
        strattonData: createErrorState(error, {}),
    })),
    on(fetchHistoricalGasQuality, (state) => ({
        ...state,
        historicalGasQuality: createLoadingState(state.historicalGasQuality)
    })),
    on(fetchHistoricalGasQualitySuccess, (state, {data}) => ({
        ...state,
        historicalGasQuality: createSuccessState(data)
    })),
    on(fetchHistoricalGasQualityError, (state, { error }) => ({
        ...state,
        historicalGasQuality: createErrorState(error, {}),
    })),
    on(fetchInternalHistoricalGasQuality, (state) => ({
        ...state,
        historicalGasQuality: createLoadingState(state.historicalGasQuality)
    })),
    on(fetchInternalHistoricalGasQualitySuccess, (state, {data}) => ({
        ...state,
        historicalGasQuality: createSuccessState(data)
    })),
    on(fetchInternalHistoricalGasQualityError, (state, { error }) => ({
        ...state,
        historicalGasQuality: createErrorState(error, {}),
    })),
    on(removeSelectedInfoPostTSP, (state) => ({
        ...state,
        headerInfoPostTSP: null
    })),
);

/**
  This function Is for saving the state to the local storage.
  It allows us to rehydrate the store between hard page loads or reloads of the applicaiton,
*/
export function infoPostLocalStorageSyncReducer(
    reducer: ActionReducer<IInfoPostState>
): ActionReducer<IInfoPostState> {
    return localStorageSync({
        keys: [
            {
                headerInfoPostTSP: {},
                selectedTspID: null
            }
        ],
        rehydrate: true,
    })(reducer);
}

export const infoPostMetaReducers: Array<MetaReducer<any, any>> = [infoPostLocalStorageSyncReducer];

function excludeSuppressTSP(state: ServiceProvider[]) {
    let tspProviders = createSuccessState(state);
    let data: ServiceProvider[] = [];

    tspProviders.data.forEach(serviceProvider => {
        if (serviceProvider.tspConfigs.filter(
            tspConfig => tspConfig.configName === TSPConstant.SuppressBLMTSP && tspConfig.configValue !== "true"
        ).length > 0)
            data.push(serviceProvider);
    });

    tspProviders.data = data;

    return tspProviders;
}
