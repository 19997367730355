import { InfoPostDetails, ReportingService } from '@gms/reporting-api';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PostingParamsModel } from './posting-response.model';
import {
  EPostingsActions,
  CreatePostingAction,
  CreatePostingFailureAction,
  CreatePostingSuccessAction,
  DeletePostingAction,
  DeletePostingActionFailure,
  DeletePostingActionSuccess,
  LoadPostingListFailureAction,
  LoadPostingListRequestAction,
  LoadPostingListSuccessAction,
  GetPostingDetailsById,
  GetPostingDetailsByIdSuccess,
  GetPostingDetailsByIdFailure,
  PostingDetailsPayload,
  IUploadPostingPayload,
  FetchPostingMenuItemsAction,
  FetchPostingMenuItemsSuccess,
  FetchPostingMenuItemsFailure,
  UploadTariffs, ITariffUploadPayload, UploadTariffsSuccess, UploadTariffsError,
  DownloadPostingDocumentAction,
  DownloadPostingDocumentActionSuccess,
  DownloadPostingDocumentActionFailure,
} from './posting.actions';
import { InfoPostMenuItems } from "@gms/reporting-api/model/infoPostMenuItems";

@Injectable({
  providedIn: 'root',
})
export class PostingEffects {
  constructor(
    private actions$: Actions,
    private postingService: ReportingService,
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPostingListRequestAction>(EPostingsActions.FETCH_POSTING_LIST),
    map(action => action.payload),
    switchMap(action => {
      const params: PostingParamsModel = action;
      const infoPostDetails = {
        tspId: params.tspId,
        infoPostId: params.infoPostID,
        contractNumber: params.contractNumber,
        datetimePostingEffective: params.postingEffectiveOn,
        description: params.description,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        infoPostName: params.infoPostName,
        sortBy: params.sortBy[0].field,
        sortDescending: (params.sortBy[0].dir === 'desc') ? true : false,
        groupCode: params.groupCode
      }

      let infoPost$: Observable<Array<InfoPostDetails>>;

      if (params.groupCode === "BISA") {
        infoPost$ = this.postingService.getBisaPostDetails(
          infoPostDetails.tspId,
          infoPostDetails.groupCode,
          infoPostDetails.infoPostName,
          infoPostDetails.datetimePostingEffective,
          null, null,
          infoPostDetails.infoPostId,
          infoPostDetails.description,
          infoPostDetails.pageNumber,
          infoPostDetails.pageSize,
          infoPostDetails.sortBy,
          infoPostDetails.sortDescending,
          infoPostDetails.contractNumber,
          null, null
        );
      }
      else {
        infoPost$ = this.postingService.getInfoPostDetails(
          infoPostDetails.tspId,
          infoPostDetails.groupCode,
          infoPostDetails.infoPostName,
          infoPostDetails.datetimePostingEffective,
          null, null,
          infoPostDetails.infoPostId,
          infoPostDetails.description,
          infoPostDetails.pageNumber,
          infoPostDetails.pageSize,
          infoPostDetails.sortBy,
          infoPostDetails.sortDescending,
          infoPostDetails.contractNumber,
          null, null
        );
      }

      return infoPost$.pipe(
        map((items: any) => {
          return new LoadPostingListSuccessAction(items)
        }),
        catchError(error => of(new LoadPostingListFailureAction({ error: error })))
      )
    })
  ))

  getPostingDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType<GetPostingDetailsById>(EPostingsActions.FETCH_POSTING_LIST_BY_ID),
    map(action => action),
    switchMap(action => {
      const params: PostingDetailsPayload = action.payload;

      let details$: Observable<InfoPostDetails>;

      if (action.groupCode === "BISA") {
        details$ = this.postingService.getBisaPostViewDetails(params.tsp_id, params.infopost_id, params.date);
      }
      else {
        details$ = this.postingService.getInfoPostViewDetails(params.tsp_id, params.infopost_id, params.date, params.infopost_trackerids);
      }

      return details$.pipe(
        map((items: any) => {
          return new GetPostingDetailsByIdSuccess(items)
        }),
        catchError(error => of(new GetPostingDetailsByIdFailure({ error: error })))
      )
    })
  ))

  createRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<CreatePostingAction>(EPostingsActions.CREATE_NEW_POSTING),
    switchMap(action => {
      const params: IUploadPostingPayload = action.payload;

      let createPosting$: Observable<InfoPostDetails>;

      if (action.groupCode === "BISA") {
        createPosting$ = this.postingService.postBisaInfoPostDetails(
          params.tspId, params.menuItem, params.description, params.datetimePostingEffective,
          params.document, params.datetimePostingExpire, params.contractId,
          params.active, params.postImmediately, params.alternateDocument,
          params.displayOrder, params.updateDateTimePostingEffective, params.additionalProperties,
          params.userId,
          params.isLargeFileUploaded
        );
      }
      else {
        createPosting$ = this.postingService.postInfoPostDetails(
          params.tspId, params.menuItem, params.description, params.datetimePostingEffective,
          params.document, params.datetimePostingExpire, params.contractId,
          params.active, params.postImmediately, params.alternateDocument,
          params.displayOrder, params.updateDateTimePostingEffective, params.additionalProperties,
          params.userId,
          params.isLargeFileUploaded
        )
      }

      return createPosting$.pipe(
        map((item: any) => {
          return new CreatePostingSuccessAction(item)
        }),
        catchError(error => of(new CreatePostingFailureAction({ error: error })))
      )
    })
  ))

  deleteRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePostingAction>(EPostingsActions.DELETE_POSTING),
    switchMap(action => {
      const params: PostingDetailsPayload = action.payload;

      let deleteReq$: Observable<any>;

      if (action.groupCode === "BISA") {
        deleteReq$ = this.postingService.bisaDeletePosting(params.tsp_id, params.infopost_id, params.date);
      }
      else {
        deleteReq$ = this.postingService.deletePosting(params.tsp_id, params.infopost_id, params.date);
      }

      return deleteReq$.pipe(
        map((item: any) => {
          return new DeletePostingActionSuccess(true)
        }),
        catchError(error => of(new DeletePostingActionFailure({ error: error })))
      )
    })
  ))

  fetchPostingMenuItemsEffect$ = createEffect(() => this.actions$.pipe(
    ofType<FetchPostingMenuItemsAction>(EPostingsActions.FETCH_MENU_ITEM_LIST),
    map(action => action.info),
    switchMap(action => {

      let infoPostMenu$: Observable<InfoPostMenuItems>;

      if (action.group_code === "BISA") {
        infoPostMenu$ = this.postingService.getBisaPostMenuItems(action.tspID, action.group_code);
      }
      else {
        infoPostMenu$ = this.postingService.getInfoPostMenuItems(action.tspID, action.group_code);
      }

      return infoPostMenu$.pipe(
        map((items: InfoPostMenuItems) => {
          return new FetchPostingMenuItemsSuccess(items)
        }),
        catchError(error => of(new FetchPostingMenuItemsFailure({ error: error })))
      )
    })
  ))

  UploadTariffs: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadTariffs>(EPostingsActions.UPLOAD_TARIFF_POSTING),
      map((action: UploadTariffs) => action.payload),
      switchMap((payload: ITariffUploadPayload) => {
        return this.postingService
          .s3FileexchangeUpload(payload.file, payload.contentType, payload.fileName, null)
          .pipe(
            map(response => {
              return new UploadTariffsSuccess({
                ...{ success: true },
              });
            }),
            catchError(error => {
              return of(new UploadTariffsError(error));
            })
          );
      })
    )
  );

  downloadPostingDocument$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadPostingDocumentAction>(EPostingsActions.DOWNLOAD_POSTING_DOCUMENT),
      map(action => action.infopostTrackID),
      switchMap((infopostTrackID: number) => {

        return of(`${this.postingService.configuration.basePath}/infopost/postings?postingsDocumentId=${infopostTrackID}`)
          .pipe(
            map(resp => {
              window.open(resp, '_blank', 'noopener');
              return new DownloadPostingDocumentActionSuccess();
            }),
            catchError(error => of(new DownloadPostingDocumentActionFailure(error)))
          )
      })
    )
  );

}
